import * as React from 'react';
import Story from 'styleguide/components/Story/Story';

import img from 'assets/images/stories/make-something-epic.jpg';

const DescriptionBlock = () => (
  <div>
    <p>
      State-of-the-art production presses with G7 color workflows. Comprehensive file checks and intent-driven
      quality control.
    </p>
    <p>A 98% customer satisfaction rate that no printer our size or larger can match.</p>
    Great idea? Meet great execution.
  </div>
);

const StoryEpic = () => (
  <Story
    intro="Make Something"
    title="Epic"
    description={<DescriptionBlock />}
    imageUrlMobile={img}
    imageUrlDesktop={img}
    direction="right"
    imageAlt="Make something epic"
    imageTitle="Make Something Epic"
  />
);

export default StoryEpic;
